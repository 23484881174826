<template>
    <div class="product-conflicting-container">
        <div>
            <div class="product-conflicting-content">
                <div class="product-conflicting-back">
                    <router-link to="/account">
                        <chevron :iconDirection="'left'" />
                    </router-link>
                </div>
                <div class="product-conflicting-title">
                    {{ $t("email_update_title") }}
                </div>
            </div>
            <div class="product-conflicting-buttonsgroup">
                <div>
                    <label>{{ $t("email_current") }}</label>
                    <div class="account-current-email-content">{{ getUserName }}</div>
                </div>
                <div>
                    <label>{{ $t("email_update_new") }}</label>
                    <input @input="checkNewEmail" v-model="newEmail" type="text" inputmode="email" autocapitalize="off" class="product-conflicting-button" placeholder="johndoe@gmail.com"
                    :class="{ 'is-invalid': isNewEmailInvalid }" />
                    <p class="email_update_information">{{ $t("email_update_information") }}</p>
                    <div class="is-invalid-text" v-if="isNewEmailInvalid">
                        {{ $t("incorrect_email") | capitalize }}
                    </div>
                </div>
                <div>
                    <label>{{$t('check_password')}}</label>
                    <input ref="passwordInput" v-model="password" @input="checkPassword" type="password" id="inputPassword" 
                    class="product-conflicting-button" :placeholder="$t('login_password')"
                    :class="{ 'is-invalid': isPasswordInvalid }" required>
                    <div v-if="isPasswordValidated === false" class="is-invalid-text">
                        {{ $t("incorrect_password") | capitalize }}
                    </div>
                </div>
            </div>
        </div>
        <div class="product-conflicting-footer">
            <b-button class="subscription-button" block
            :disabled="!(isNewEmailValid && isPasswordCorrect)" @click="submitNewEmail()">
            {{ $t("Save") }}
        </b-button>
        </div>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";

import Chevron from '../svg/chevron.vue';
export default {
    components: { Chevron },
    name: "AccountEmailUpdate",

    data() {
        return {
            newEmail: "",
            isNewEmailValidating: null,
            password: "",
            remaining: 0,
            showPassword: false,
            isNewEmailInvalid: false,
            isPasswordInvalid: false,
            isPasswordValidated: null,
        };
    },

    computed: {
        ...mapGetters("users", ["getUserName"]),
        isNewEmailValid() {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return this.newEmail && this.newEmail !== this.getUserName && validRegex.test(this.newEmail);
        },
        isPasswordCorrect() {
            return this.password.trim() !== "";
        }
    },

    methods: {
        ...mapActions("users", ["sendEmailConfirmation", "fetchUserData"]),
        async submitNewEmail() {
            this.verifyAuth(this.newEmail, this.password)
        },
        checkNewEmail() {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            this.isNewEmailInvalid = !(this.newEmail && this.newEmail !== this.getUserName && validRegex.test(this.newEmail));
        },
        checkPassword() {
            this.isPasswordInvalid = this.password.trim() === "";
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        verifyAuth(userName, password) {
            window.location.href = `/verify_auth?email=${userName}&password=${password}&return_path=account_email_update_complete`;
        },
        updateFromRoute() {
            let parameters = this.$route.query;
            if (parameters["email"]) {
                this.isNewEmailValidating = parameters["email"].replace(" ", "+");
                this.newEmail = this.isNewEmailValidating;
            }
            if (parameters["validated"]) {
                this.isPasswordValidated = parameters["validated"] === "true";
            }
        }
    },

    async created() {
        this.updateFromRoute();
    },
    
    async beforeRouteUpdate(to, from, next) {
        this.updateFromRoute();
        next();
    },
    
    async mounted() {
        this.fetchUserData();
        window.zE("webWidget", "hide");
    }
};
</script>
  
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.product-conflicting-container {
    height: 90vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-conflicting-content {
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 1.5em;
    }
    .product-conflicting-footer {
        display: flex;
        gap: 1em;
        padding: 1.5em;
        width: 100%;
    }
    .product-conflicting-title {
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        font-size: 28px;
        text-align: left;
    }

    .product-alert-text {
        text-align: left;
        color: #f95658 !important;
        font-weight: 500 !important;
    }

    .image-container {
        flex: 1;
        display: flex;
        position: relative;
        justify-content: center;
        overflow: hidden;
    }

    .product-conflicting {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        &-image {
            position: absolute;
            width: 50%;
            bottom: 0;
        }

        &-buttonsgroup {
            padding: 1.5em;
            display: flex;
            flex-direction: column;
            gap: 1em;
            width: 100%;

            .is-invalid-text {
                color: #EB0000 !important;
                font-weight: 500 !important;
            }
            .product-conflicting-button.is-invalid {
                border: 2px solid #EB0000 !important;
                font-weight: 500 !important;
                background: transparent !important;
            }

            .product-conflicting-button {
                border-radius: 18px;
                border: 2px solid var(--color-neutral-shades-300, #D9D9D9);
                background: var(--color-neutral-shades-0, #FFF);
                width: 100%;
                padding: 1em;

                &:focus {
                    box-shadow: none;
                }

                &:focus-visible {
                    outline: none;
                }

                &-finish {
                    font-size: 18px;
                    padding: 1em;
                }

                &-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: left;
                }

                &-text {
                    width: 90%;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #555b62;
                }
            }

            .btn-secondary:not(:disabled):not(.disabled).is-invalid {
                border: 1px solid #f95658 !important;
                background: #feeeee;
                box-shadow: 0px 0px 0px 1px #feeeee !important;
            }

            .btn-secondary:not(:disabled):not(.disabled).active {
                border: 1px solid #0090f8 !important;
                box-shadow: 0px 0px 0px 1px #0090f8 !important;

                .product-conflicting-button-text {
                    color: black;
                }
            }

            .account-expand-summary {
                display: flex;
                flex-direction: column;
                gap: 0.5em;

                .expand-summary-item {
                    font-family: "Open Sans";
                    font-weight: 400;
                    font-size: 14px;
                    color: #555b62;
                    text-align: left;

                    &:last-child {
                        padding-bottom: 1em;
                    }
                }
                .expand-summary-item.is-invalid {
                    color: #f95658 !important;
                    font-weight: 500 !important;
                    border: none !important;
                    background: transparent !important;
                }
            }
        }
    }
}

label {
    color: var(--color-neutral-body, #3B3B3B);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: .4em;
}
.account-current-email-content {
    width: 100%;
    border-radius: 18px;
    border: 2px solid rgba(217, 217, 217, 0.00);
    background: var(--color-primary-300, #E0F0FF);
    padding: 1em;
    color: var(--color-neutral-body, #3B3B3B);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.email_update_information {
    margin-top: .4em;
    margin-bottom: 0;
    text-align: left;
    color: #434343;
    font-size: .8em;
    font-weight: 500;
    line-height: 140%;
}

.btn {
  background: radial-gradient(135.75% 139.94% at 0% 50%, #418FDE 0%, #1D60E0 43.64%);
  border-color: transparent;
  border-radius: 18px;
  padding: 1.125em 4.8em;
  box-shadow: 0px 5px #174396;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  color: #F1F8FD;
  transition: background opacity color box-shadow .5s ease;
}
.btn.disabled, .btn:disabled {
    background: #F0F0F0;
    opacity: 1;
    color: #8C8C8C;
    box-shadow: 0px 5px #F0F0F0;
}
</style>